// https://github.com/KaranAgarwalla/Encryption-Decryption/blob/master/ngrams2.txt
export const bigrams = [
  "th",
  "he",
  "in",
  "er",
  "an",
  "re",
  "on",
  "at",
  "en",
  "nd",
  "ti",
  "es",
  "or",
  "te",
  "of",
  "ed",
  "is",
  "it",
  "al",
  "ar",
  "st",
  "to",
  "nt",
  "ng",
  "se",
  "ha",
  "as",
  "ou",
  "io",
  "le",
  "ve",
  "co",
  "me",
  "de",
  "hi",
  "ri",
  "ro",
  "ic",
  "ne",
  "ea",
  "ra",
  "ce",
  "li",
  "ch",
  "ll",
  "be",
  "ma",
  "si",
  "om",
  "ur",
  "ca",
  "el",
  "ta",
  "la",
  "ns",
  "di",
  "fo",
  "ho",
  "pe",
  "ec",
  "pr",
  "no",
  "ct",
  "us",
  "ac",
  "ot",
  "il",
  "tr",
  "ly",
  "nc",
  "et",
  "ut",
  "ss",
  "so",
  "rs",
  "un",
  "lo",
  "wa",
  "ge",
  "ie",
  "wh",
  "ee",
  "wi",
  "em",
  "ad",
  "ol",
  "rt",
  "po",
  "we",
  "na",
  "ul",
  "ni",
  "ts",
  "mo",
  "ow",
  "pa",
  "im",
  "mi",
  "ai",
  "sh",
  "ir",
  "su",
  "id",
  "os",
  "iv",
  "ia",
  "am",
  "fi",
  "ci",
  "vi",
  "pl",
  "ig",
  "tu",
  "ev",
  "ld",
  "ry",
  "mp",
  "fe",
  "bl",
  "ab",
  "gh",
  "ty",
  "op",
  "wo",
  "sa",
  "ay",
  "ex",
  "ke",
  "fr",
  "oo",
  "av",
  "ag",
  "if",
  "ap",
  "gr",
  "od",
  "bo",
  "sp",
  "rd",
  "do",
  "uc",
  "bu",
  "ei",
  "ov",
  "by",
  "rm",
  "ep",
  "tt",
  "oc",
  "fa",
  "ef",
  "cu",
  "rn",
  "sc",
  "gi",
  "da",
  "yo",
  "cr",
  "cl",
  "du",
  "ga",
  "qu",
  "ue",
  "ff",
  "ba",
  "ey",
  "ls",
  "va",
  "um",
  "pp",
  "ua",
  "up",
  "lu",
  "go",
  "ht",
  "ru",
  "ug",
  "ds",
  "lt",
  "pi",
  "rc",
  "rr",
  "eg",
  "au",
  "ck",
  "ew",
  "mu",
  "br",
  "bi",
  "pt",
  "ak",
  "pu",
  "ui",
  "rg",
  "ib",
  "tl",
  "ny",
  "ki",
  "rk",
  "ys",
]
