// https://github.com/KaranAgarwalla/Encryption-Decryption/blob/master/ngrams3.txt
export const trigrams = [
  "the",
  "and",
  "ing",
  "ion",
  "tio",
  "ent",
  "ati",
  "for",
  "her",
  "ter",
  "hat",
  "tha",
  "ere",
  "ate",
  "his",
  "con",
  "res",
  "ver",
  "all",
  "ons",
  "nce",
  "men",
  "ith",
  "ted",
  "ers",
  "pro",
  "thi",
  "wit",
  "are",
  "ess",
  "not",
  "ive",
  "was",
  "ect",
  "rea",
  "com",
  "eve",
  "per",
  "int",
  "est",
  "sta",
  "cti",
  "ica",
  "ist",
  "ear",
  "ain",
  "one",
  "our",
  "iti",
  "rat",
  "nte",
  "tin",
  "ine",
  "der",
  "ome",
  "man",
  "pre",
  "rom",
  "tra",
  "whi",
  "ave",
  "str",
  "act",
  "ill",
  "ure",
  "ide",
  "ove",
  "cal",
  "ble",
  "out",
  "sti",
  "tic",
  "oun",
  "enc",
  "ore",
  "ant",
  "ity",
  "fro",
  "art",
  "tur",
  "par",
  "red",
  "oth",
  "eri",
  "hic",
  "ies",
  "ste",
  "ght",
  "ich",
  "igh",
  "und",
  "you",
  "ort",
  "era",
  "wer",
  "nti",
  "oul",
  "nde",
  "ind",
  "tho",
  "hou",
  "nal",
  "but",
  "hav",
  "uld",
  "use",
  "han",
  "hin",
  "een",
  "ces",
  "cou",
  "lat",
  "tor",
  "ese",
  "age",
  "ame",
  "rin",
  "anc",
  "ten",
  "hen",
  "min",
  "eas",
  "can",
  "lit",
  "cha",
  "ous",
  "eat",
  "end",
  "ssi",
  "ial",
  "les",
  "ren",
  "tiv",
  "nts",
  "whe",
  "tat",
  "abl",
  "dis",
  "ran",
  "wor",
  "rou",
  "lin",
  "had",
  "sed",
  "ont",
  "ple",
  "ugh",
  "inc",
  "sio",
  "din",
  "ral",
  "ust",
  "tan",
  "nat",
  "ins",
  "ass",
  "pla",
  "ven",
  "ell",
  "she",
  "ose",
  "ite",
  "lly",
  "rec",
  "lan",
  "ard",
  "hey",
  "rie",
  "pos",
  "eme",
  "mor",
  "den",
  "oug",
  "tte",
  "ned",
  "rit",
  "ime",
  "sin",
  "ast",
  "any",
  "orm",
  "ndi",
  "ona",
  "spe",
  "ene",
  "hei",
  "ric",
  "ice",
  "ord",
  "omp",
  "nes",
  "sen",
  "tim",
  "tri",
  "ern",
  "tes",
  "por",
  "app",
  "lar",
  "ntr",
]
